import {createInertiaApp} from '@inertiajs/vue3';
import * as Sentry from "@sentry/vue";
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {i18nVue} from 'laravel-vue-i18n';
import {createApp, h} from 'vue';
import {ZiggyVue} from '../../vendor/tightenco/ziggy/dist/vue.m';
import '../css/app.css';
import './bootstrap';

const appName = import.meta.env.VITE_APP_NAME || 'linklist';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });

        Sentry.init({
            app,
            dsn: 'https://39212167fd6b0ce8c46db5ef33dee83e@o1033858.ingest.us.sentry.io/4505737744482304',//import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            environment: import.meta.env.APP_ENV,
        });

        app
            .use(plugin)
            .use(i18nVue, {
                resolve: lang => import(`../../lang/${lang}.json`)
            })
            .use(ZiggyVue, Ziggy)
            .mount(el);
        return app;
    },
    progress: {
        color: '#4B5563',
    },
});
